const url = require('url');

function pad(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

export class SDKUtils {
  static getPageHash(page_url) {
    var hash = 0;
    if (page_url.length == 0) return hash;
    for (var i = 0; i < page_url.length; i++) {
      var charCode = page_url.charCodeAt(i);
      hash = ((hash<<5)-hash)+charCode;
      hash = hash & hash; // Convert to 32bit integer
    }
    // if hash is a negative number, remove '-' and append '0' in front
    if (hash < 0) {
      hash = "0" + (-hash);
    } else {
      hash = "" + hash;
    }
    return hash;
  }

  static getBooleanValue(value) {
    if (value === undefined || value == null) {
      return false;
    }
    value = value.toLowerCase();
    if (value =="1" || value=="true" || value=="on" || value=="t") {
       return true;
    }
    return false;
  }

  static normalizeUrl(original_url, whitelist_parameters, normalize_request_keys) {
    const urlObject = url.parse(original_url, true);

    // www.valuemyweb.com
    var hostname = urlObject.hostname;
    // http:
    var protocol = urlObject.protocol;
    // ""
    var port = urlObject.port;
    // "/exclude/js-sdk-test.php"
    var path = urlObject.pathname;
    if (protocol == 'http:' && port == 80) {
      port = ""
    } else if (protocol == 'https:' && port == 443) {
      port = ""
    }

    var query_dict = urlObject.query;

    // don't print null for port
    if (port == null) {
      port = '';
    }
    if (port.length > 0) {
      port = ':' + port
    }

    var keys = Object.keys(query_dict).sort();
    var queryStringList = [];
    if (keys.length > 0) {
      for (var keyIndex=0; keyIndex<keys.length; keyIndex++) {
        var key = keys[keyIndex];
        var normalized_key = key;
        if (normalize_request_keys) {
            normalized_key = key.toLowerCase();
        }
        if (!whitelist_parameters || !whitelist_parameters.includes(normalized_key)) {
          continue;
        }
        if (query_dict[key] instanceof Array) {
          var sorted_values = query_dict[key].sort();
          sorted_values.forEach(function(value){
            var norm_value = encodeURIComponent(value);
            queryStringList.push(normalized_key + "=" + norm_value);
          });
        } else {
          var value = encodeURIComponent(query_dict[key]);
          queryStringList.push(normalized_key + "=" + value);
        }
      }
    }

    if (queryStringList.length > 0) {
      var queryString = queryStringList.join('&');
      return protocol + '//' + hostname + port + path + '?' + queryString
    } else {
      return protocol + '//' + hostname + port + path
    }
  }

  // get a parameter dictionary from url
  static getParameterDictionaryFromUrl(original_url) {
    return url.parse(original_url, true).query;
  }

  static overrideHostInURL(original_url, canonicalHost) {
    var parts = canonicalHost.split(':')
    var canonicalPort = -1;;
    if (parts.length == 2) {
      canonicalHost = parts[0];
      canonicalPort = parts[1];
    }
    const urlObject = url.parse(original_url, true);
    var protocol = urlObject.protocol;

    urlObject.hostname = canonicalHost;
    // url format uses host rather than hostname:port
    urlObject.host = canonicalHost;

    if (canonicalPort > -1) {
      if (!((protocol == 'http:' && canonicalPort == 80) ||
          (protocol == 'https:' && canonicalPort == 443))) {
        urlObject.port = canonicalPort;
        urlObject.host = urlObject.host + ":" + canonicalPort;
      }
    }

    return url.format(urlObject);
  }

  static overrideProtocolInURL(original_url, protocol) {
    const urlObject = url.parse(original_url, true);
    urlObject.protocol = protocol + ":";
    return url.format(urlObject);
  }

  static userAgentMatchesRegex(user_agent, user_agent_regex_pattern) {
    var user_agent_regex = new RegExp(user_agent_regex_pattern,'gi')
    return user_agent_regex.test(user_agent)
  }


  static isDaylightSavings(day, month, dow) {
    // month [1,12]
    // dow [0,6]

    // January, february, and december are out.
    if (month < 3 || month > 11) { return false; }
    // April to October are in
    if (month > 3 && month < 11) { return true; }
    var previousSunday = day - dow;
    // In march, we are DST if our previous sunday was on or after the 8th.
    if (month == 3) { return previousSunday >= 8; }
    // In november we must be before the first sunday to be dst.
    // That means the previous sunday must be before the 1st.
    return previousSunday <= 0;
  }

  static convertToNormalizedGoogleIndexTimeZone(epoch_milliseconds, prefix) {
    // get date in current location
    var local_date = new Date(epoch_milliseconds)

    // convert to target timestamp
    var offset = -8; // standard PST offset
    var target_timestamp = local_date.getTime() + (local_date.getTimezoneOffset() * 60000);
    var target_date = new Date(target_timestamp + (3600000*offset));

    if (SDKUtils.isDaylightSavings(target_date.getDate(), target_date.getMonth()+1, target_date.getDay())) {
       offset = -7; // PDT offset
       target_date = new Date(target_timestamp + (3600000*offset));
    }

    return prefix + "y_" + target_date.getFullYear() + "; " +
      prefix + "m_" + pad(target_date.getMonth()+1, 2) + "; " +
      prefix + "d_" + pad(target_date.getDate(), 2) + "; " +
      prefix + "h_" + pad(target_date.getHours(), 2) + "; " +
      prefix + "mh_" + pad(target_date.getMinutes(), 2) + "; " +
      prefix + "_epoch:" + epoch_milliseconds;
  }

  static convertToNormalizedTimeZone(epoch_milliseconds, prefix) {
    // get date in current location
    var local_date = new Date(epoch_milliseconds);

    // convert to target timestamp
    var offset = -8; // standard PST offset
    var target_timestamp = local_date.getTime() + (local_date.getTimezoneOffset() * 60000);
    var target_date = new Date(target_timestamp + (3600000*offset));

    if (SDKUtils.isDaylightSavings(target_date.getDate(), target_date.getMonth()+1, target_date.getDay())) {
       offset = -7; // PDT offset
       target_date = new Date(target_timestamp + (3600000*offset));
    }

    return prefix + "_tstr:" + target_date.toString() + "; " +
      prefix + "_epoch:" + epoch_milliseconds;
  }


  static matchIncludeRules(include_rules, normalized_url) {
    for (var rule_index = 0; rule_index < include_rules.length; rule_index++) {
      var regex = RegExp(include_rules[rule_index]);
      if (regex.test(normalized_url)) {
        return true;
      }
    }
    return false;
  }

  static derivePageGroup(normalized_url, page_group_config) {
    for (var pg_index=0; pg_index < page_group_config.length; pg_index++) {
      var include_rules = page_group_config[pg_index]['include_rules'];
      var exclude_rules = page_group_config[pg_index]['exclude_rules'];
      var rule_name = page_group_config[pg_index]['name'];
      var includeMatch = false;
      if (exclude_rules) {
        // scan through the exclude rules first
        var excludeMatch = false;
        for (var rule_index = 0; rule_index < exclude_rules.length; rule_index++) {
          var regex = RegExp(exclude_rules[rule_index]);
          if (regex.test(normalized_url)) {
            excludeMatch = true;
            break;
          }
        }
        // check the include rules only if exclude rules don't match
        if (!excludeMatch) {
          if (SDKUtils.matchIncludeRules(include_rules, normalized_url)) {
            return rule_name;
          }
        }
      } else {
        if (SDKUtils.matchIncludeRules(include_rules, normalized_url)) {
          return rule_name;
        }
      }
    }
    return null;
  }
}

/**
 * Simple implementation of Object.assign()
 * @param target: original object
 * @param source: additional object
 * @returns merged object
 */
export function simpleAssign(target, source) {
  if (source !== null && source !== undefined) {
    for (let key in source) {
      if (source.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    }

    return target;
  }
}
